<script setup lang="ts">
  interface Props {
    modalContent: {
      MIGX_id: number
      title: string
      text: string
    }[]
    googleLink: string
    lat: number
    lon: number
  }

  defineProps<Props>();

  const showModal = defineModel<boolean>();
</script>

<template>
  <Teleport v-if="showModal" to="body">
    <div class="modal-location">
      <div class="title-container">
        <span class="title">Как добраться</span>
        <div class="icon-container" @click="showModal = false">
          <IconClose class="icon" />
        </div>
      </div>
      <div class="location-container">
        <details v-for="question in modalContent" :key="question.MIGX_id" class="question-container">
          <summary class="question">
            <span class="question-text">{{ question.title }}</span>
            <IconChevronDown class="chevron" />
          </summary>
          <div class="answer">
            {{ question.text }}
          </div>
        </details>
      </div>
      <div class="modal-buttons">
        <NuxtLink :to="`https://yandex.ru/maps/?ll=${lon},${lat}&z=18&&rtt=mt&rtext=~${lat},${lon}`" external target="_blank" type="button" class="button-map yandex">
          Яндекс карты
        </NuxtLink>
        <NuxtLink :to="googleLink" external target="_blank" type="button" class="button-map google">
          Google карты
        </NuxtLink>
      </div>
    </div>
    <div class="overlay" @click.stop="showModal = false"></div>
  </Teleport>
</template>

<style scoped lang="scss">
.modal-location {
  z-index: 102;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  width: 680px;
  height: 684px;
  padding: 40px;
  border-radius: 30px;
  background-color: #FFF;
  box-shadow: 0px 2px 24px 0px rgba(135, 143, 162, 0.15);
  @media (max-width: 1023px) {
    width: 100%;
    heigth: 100%;
    box-shadow: none;
  }
}
.title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  color: #171E36;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.05px;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50px;
  background-color: #F2F3F6;
  cursor: pointer;
}
.icon {
  font-size: 20px;
  margin: 0;
  color: #262633;
}
.location-container {
  @include scroll;
  scrollbar-gutter: stable;
  overflow-y: auto;
  height: 480px;
  margin: 24px 0px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
summary {
  list-style: none;
  &::-webkit-details-marker {
    display: none;
  }
}
.question-container {
  width: 100%;
}
.question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px;
  border-radius: 15px;
  background-color: #F2F3F6;
}
.question-text {
  color: #262633;
  font-feature-settings: 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.chevron {
  font-size: 24px;
  margin: 0;
}
details[open] .chevron {
  transform: rotate(180deg);
}
.answer {
  /* padding: 16px 8px; */
  padding: 16px 7px;
  color: var(--Black-, #262633);
  font-feature-settings: 'liga' off;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.08px;
}
.modal-buttons {
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 350px) {
    gap: 4px;
  }
}
.button-map {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  width: 100%;
  height: 56px;
  padding: 0px 19.55px;
  color: white !important;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.024px;
  text-decoration: none;
  @media (max-width: 400px) {
    padding: 0px 8px;
    height: 50px;
    font-size: 0.875rem;
  }
}
.yandex {
  background-color: #E85032;
}
.google {
  background-color: #497DFF;
}
.overlay {
  z-index: 101;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  @media (max-width: 1023px) {
    background-color: white;
  }
}
</style>
